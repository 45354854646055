import { createKey, getProfiles, signIn, deployContractTest, deployContractFromJson, deployContractFromWasm, deployContract, getBalance, getItem, runMethod, getLogInStatus, signOut, getSignInState, getLatest, getPage } from './deployContract';
import { deploy2, initializeMetamaskWithTimeout, getLatestEthereum, getPageEthereum, loadEthereum, runMethodEthereum, getMetamaskState, runCurrencyMethodEthereum, getVerifiedAccount } from "./deployContractEthereum";


import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import * as ethers from 'ethers';

import { createWalletClient, custom } from "viem";
import { mainnet, sepolia, goerli, polygon, polygonAmoy, optimism, optimismGoerli, optimismSepolia, arbitrum, arbitrumGoerli, arbitrumSepolia, base, baseGoerli, baseSepolia } from "viem/chains";

import { createLightAccountAlchemyClient, alchemyGasManagerMiddleware } from "@alchemy/aa-alchemy";
import {
    WalletClientSigner,
} from "@alchemy/aa-core";

var subscribed = false;

// subscribe to lifecycle events emitted by web3auth
function SubscribeToEvents(){
    if (subscribed) {
        return;
    }
    subscribed = true;
    //web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
    //    console.log("XXXXXX connected to wallet", data);
    //    // web3auth.provider will be available here after user is connected
    //});
    //web3auth.on(ADAPTER_EVENTS.ADAPTER_DATA_UPDATED, (data) => {
    //    console.log("XXXXXX Adapter data updated", data);
    //});
    //web3auth.on(ADAPTER_EVENTS.NOT_READY, () => {
    //    console.log("XXXXXX not ready");
    //});
    //web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
    //    console.log("XXXXXX connecting");
    //});
    //web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
    //    console.log("XXXXXX disconnected");
    //});
    //web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
    //    console.log("XXXXXX error", error);
    //});
    //web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
    //    console.log("XXXXXX error", error);
    //});
};


let metamaskAdapter = null;


const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x1",
    rpcTarget: "https://rpc.ankr.com/eth",
    displayName: "Ethereum Mainnet",
    blockExplorer: "https://etherscan.io",
    ticker: "ETH",
    tickerName: "Ethereum",
};

const web3auth = new Web3AuthNoModal({
    clientId: "BHQlHOti6dLx9jEqH40XjWeCCizBYY3Zg-c7bdVrCIGd_bVYNzpk8WSo3ebu7eaEhI6m-asW3kECfSXV_4dlTSk",
    web3AuthNetwork: "sapphire_mainnet",
    chainConfig,
    sessionTime: 604800
});

const privateKeyProvider = new EthereumPrivateKeyProvider({
    config: { chainConfig },
});

const openloginAdapter = new OpenloginAdapter({
    adapterSettings: {
        uxMode: "redirect",
        loginConfig: {
            jwt: {
                verifier: "toolblox-mainnet",
                typeOfLogin: "jwt",
                clientId: "BdLTppp2ZZ4AFXymEyOW1di1IlS1Jadh",
            },
            google: {
                verifier: "toolblox-mainnet-google", // Pass the Verifier name here
                typeOfLogin: "google", // Pass on the login provider of the verifier you've created
                clientId: "701532743249-85aakmg6o8a6mq677rl6j5iublg4trtv.apps.googleusercontent.com", // Pass on the Google `Client ID` here
            },
            github: {
                verifier: "toolblox-mainnet-google", // Pass the Verifier name here
                typeOfLogin: "github", // Pass on the login provider of the verifier you've created
                clientId: "66560ed74a6b1cf9fdc6", // Pass on the Google `Client ID` here
            },
        },
    },
    privateKeyProvider,
});

var authInit = false;

export function GetAAChain(chainId) {
    return [mainnet, sepolia, goerli, polygon, polygonAmoy, optimism, optimismGoerli, optimismSepolia, arbitrum, arbitrumGoerli, arbitrumSepolia, baseGoerli]//base, baseSepolia
        .find(c => c.id == parseInt(chainId));
}

export function IsAASupported(net) {
    if (!isAaEnabled) {
        return;
    }
    var adapter = localStorage.getItem("CachedAdapter");
    var aaChain = GetAAChain(net.details.chainId);
    return adapter !== "metamask" && aaChain != null && aaChain != undefined;
}

export async function GetAAWalletAddress(net) {
    if (net == null || net == undefined || !IsAASupported(net)) {
        return null;
    }
    const { connectedProvider } = await InitializeAA(null, net);
    return await connectedProvider.getAddress();
}
function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

let _cachedAAChain;
let _cachedAAConfiguration;
let _jwtExpiry = new Date(-8640000000000000);

export async function ConfigureAA(caller, methodName, getAaJwtMethodName, aaEnabled, gasPolicyEnabled, net) {
    isAaEnabled = aaEnabled;
    isGasPolicyEnabled = gasPolicyEnabled;
    _cachedAAChain = null;
    _cachedAAConfiguration = null;
    authInit = false;
    chainUpdate = async function (net) {
        var aa = await GetAAWalletAddress(net);
        await caller.invokeMethodAsync(methodName, net?.details?.chainId, aa);
    };
    getAaJwt = async function (island) {
        return await caller.invokeMethodAsync(getAaJwtMethodName, island);
    };
    //if (IsAASupported(net)) {
    //    await InitializeAA(null, net);
    //}
}

function createDeferred(chain, method, net) {
    const promise = new Promise(async (res, rej) => {

        try {

            const alchemyJwt = await getAaJwt(net.name); //retrieve short lived access token

            let web3authProvider = await GetWeb3AuthProvider(method, net);

            const walletClient = createWalletClient({ transport: custom(web3authProvider) });

            const web3AuthSigner = new WalletClientSigner(walletClient, "web3auth");

            let clientConfig = {
                signer: web3AuthSigner,
                chain,
                jwt: alchemyJwt,
                rpcUrl: `https://${net.alchemyIdentifier}.g.alchemy.com/v2/`
            };

            if (net.gasPolicy != null && net.gasPolicy != undefined && isGasPolicyEnabled) {
                clientConfig.gasManagerConfig = { policyId: net.gasPolicy };
            }

            let connectedProvider = await createLightAccountAlchemyClient(clientConfig);

            //console.log("Using smart account " + (await alchemyProvider.getAddress()));

            _jwtExpiry = addMinutes(new Date(), 19);
            res({ web3AuthSigner, connectedProvider });
        } catch (err) {
            rej(err);
        }
    });
    return promise;
}
export async function InitializeAA(method, net) {
    const chain = GetAAChain(net.details.chainId);

    if (chain && _cachedAAChain?.id === chain.id && _jwtExpiry > new Date()) {

        return await _cachedAAConfiguration;
    }

    if (!chain) {
        return null;
    }

    _cachedAAChain = chain;
    _cachedAAConfiguration = createDeferred(chain, method, net);
    return await _cachedAAConfiguration;
}

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function EnsureEthers() {
    if (inIframe()) {
        try {
            window.ethereum = window.top.ethereum;
            window.web3 = window.top.web3;
        } catch {}
    }
}

function GetMetamaskAdapter(net) {
    if (metamaskAdapter) {
        return metamaskAdapter;
    }
    if (net == null || net == undefined) {
        metamaskAdapter = new MetamaskAdapter({
            sessionTime: 86400, // 1 hour in seconds
            web3AuthNetwork: "sapphire_mainnet",
            
        });
    } else {

        metamaskAdapter = new MetamaskAdapter({
            sessionTime: 86400, // 1 hour in seconds
            web3AuthNetwork: "sapphire_mainnet",
            chainConfig: GetChainConfig(net),
        });
    }
    return metamaskAdapter;
}

export async function InitAuth(method, net) {
    const cat = method ?? localStorage.getItem("CachedAdapter");
    if (cat == null) {
        return false;
    }
    if (authInit && !web3auth.connected) {
        await MyLib.Web3AuthConnect(cat);
    }
    if (authInit) {
        return web3auth.connected;
    }
    authInit = true;
    try {
        SubscribeToEvents();
        web3auth.configureAdapter(GetMetamaskAdapter(net));
        web3auth.configureAdapter(openloginAdapter);
        await web3auth.init();
    } catch (error) {
        console.log(error.message);
    }
    if (!web3auth.connected) {
        await MyLib.Web3AuthConnect(cat);
    }
    if (net && net.details) {
        chainUpdate(net);
    }
    return web3auth.connected;
}

export async function Web3AuthConnect(method) {
    if (web3auth.connected) {
        await web3auth.logout();
    }
    if (method == "metamask") {
        EnsureEthers();
        await web3auth.connectTo(WALLET_ADAPTERS.METAMASK, {
            appState: "Metamask"
        });
    }
    else if (method == "google") {
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "google",
        });
    }
    else if (method == "github") {
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "github",
        });
    } else {
        await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
            loginProvider: "jwt",
            extraLoginOptions: {
                domain: "https://toolblox.eu.auth0.com",
                verifierIdField: "sub", // For SMS & Email Passwordless have to use "name" as verifierIdField
            },
        });
    }
}
export async function GetLogin(token) {

    if (web3auth.status == "connected") {
        const user = await web3auth.getUserInfo();
        console.log("User info", user);

        const connectedAdapter = web3auth.walletAdapters[web3auth.connectedAdapterName];

        const ethersProvider = new ethers.BrowserProvider(connectedAdapter.provider);

        const signer = await ethersProvider.getSigner();

        const address = await signer.getAddress();

        const chain = connectedAdapter.provider.chainId;

        console.log(address);

        return { status: "connected", address: address, user: user, token: token, chain: chain };
    }
    return { status: "notconnected" };
}


export async function GetWeb3AuthProvider(method, net) {
    let connected = await InitAuth(method, net);

    if (connected) {
        const connectedAdapter = web3auth.walletAdapters[web3auth.connectedAdapterName];

        //const ethersProvider = new ethers.BrowserProvider(connectedAdapter.provider);


        return connectedAdapter.provider;
        //const connectedAdapter = web3auth.walletAdapters[web3auth.connectedAdapterName];
        //return new ethers.BrowserProvider(connectedAdapter.provider);
    }
    return null;
}

export async function LogoutWeb3Auth() {
    if (web3auth.status == "not_ready") {
        try {
            await InitAuth();
        } catch (error) {
            //console.error(error);
        }
    }
    try {
        await web3auth.logout();
    } catch (error) {
        //console.error(error);
    }

}

let chainUpdate = async function (chain) { };
let getAaJwt = async function () { };

let isAaEnabled = false;

let isGasPolicyEnabled = false;

export async function UpdateAa(aaEnabled) {
    isAaEnabled = aaEnabled;
    if (!aaEnabled) {
        _cachedAAChain = null;
        _cachedAAConfiguration = null;
    }
}

/*
*/
function GetChainConfig(net) {
    return {
        chainId: net.details.chainId,
        displayName: net.details.chainName,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        tickerName: net.details.nativeCurrency.name,
        ticker: net.details.nativeCurrency.symbol,
        decimals: net.details.nativeCurrency.decimals,
        rpcTarget: net.details.rpcUrls[0],
        blockExplorerUrl: net.details.blockExplorerUrls[0],
    };
}
export async function AddChain(net, subject) {
    subject = subject ?? web3auth;
    try {
        await subject.addChain(GetChainConfig(net));
        await subject.switchChain({ chainId: net.details.chainId });
    } catch (error) {
        console.log(error.message);

    }
    chainUpdate(net);
}

export async function SwitchChain(net) {
    await web3auth.switchChain({ chainId: net.details.chainId });
    chainUpdate(net);
}
export async function LoginWeb3Auth(method, force, net) {
    method = method ?? localStorage.getItem("CachedAdapter");
    await InitAuth(method, net, force);
    chainUpdate(net);

    var desiredAdapterName = method === "metamask" ? "metamask" : "openlogin";
    if (!web3auth.connected || force || (web3auth.connectedAdapterName != desiredAdapterName)) {
        await Web3AuthConnect(method);
    }
    const token = await web3auth.authenticateUser();
    //if (token.exp < Date.now()) {
    //    //token expired, reconnect
    //    await LogoutWeb3Auth();
    //    await Web3AuthConnect();
    //}
    return GetLogin(token.idToken);
}


export async function IsMetamaskInstalled() {

    EnsureEthers();
    return typeof window.ethereum !== 'undefined';
}

export async function GetVerifiedAccountEthereum(net, payload)
{
    return await getVerifiedAccount(net, payload);
}
export async function GetProfilesNear(config, profiles) {
    return await getProfiles(config, profiles);
}
export async function EthereumDeploy(net, bytecode, name, spec, executeAA, upgradeable) {
    return await deploy2(net, bytecode, name, spec, executeAA, upgradeable);
}

export async function GetBalance(config) {
    var balance = await getBalance(config);
    return balance;
}
export async function DeployContract(config, account, wasm) {
    var result = await deployContract(config, account, wasm);
    return result;
}
export async function DeployContractTest(config) {
    var result = await deployContractTest(config);
    return result;
}
export async function SignIn(config, account, contract) {
    var result = await signIn(config, account, contract);
    return result;
}
export async function SignOut(config, contract) {
    var result = await signOut(config, contract);
    return result;
}
export async function CreateKey(config, contractName, methodName) {
    return JSON.stringify(await createKey(config, contractName, methodName));
}
export async function GetItem(config, id, contract, account) {
    return await getItem(config, id, contract, account);
}
export async function GetLatest(config, count, contract, account) {
    return await getLatest(config, count, contract, account);
}
export async function GetPage(config, cursor, howMany, onlyMine, contract, account) {
    return await getPage(config, cursor, howMany, onlyMine, contract, account);
}
export async function LoadEthereum(net, abi, contract, id) {
    return await loadEthereum(net, abi, contract, id);
}
export async function GetLatestEthereum(net, abi, contract, count) {
    return await getLatestEthereum(net, abi, contract, count);
}
export async function GetPageEthereum(net, abi, contract, cursor, howMany, onlyMine, version) {
    return await getPageEthereum(net, abi, contract, cursor, howMany, onlyMine, version);
}
export async function RunCurrencyMethodEthereum(net, contractAddress, methodName, parameters, isAA) {
    return await runCurrencyMethodEthereum(net, contractAddress, methodName, parameters, isAA);
}
export async function RunMethodEthereum(net, abi, contract, methodName, parameters, deposit, id, gasLimit, isAA) {
    var item = await runMethodEthereum(net, abi, contract, methodName, parameters, deposit, id, gasLimit, isAA);
    return item;
}
export async function RunMethod(config, contract, account, methodName, parameters, transactionKey, deposit) {
    var item = await runMethod(config, contract, account, methodName, parameters, transactionKey, deposit);
    return item;
}
export async function CloseAllModals() {
    $(".modal").modal("hide");
}
export async function GetLogInStatus(config) {
    return await getLogInStatus(config);
}
export async function GetSignInState(config, account, fullAccessRequired) {
    return await getSignInState(config, account, fullAccessRequired);
}
export async function DeployContractFromJson(config, account, json) {
    return await deployContractFromJson(config, account, json);
}
export async function DeployContractFromWasm(config, account, wasmString) {
    return await deployContractFromWasm(config, account, wasmString);
}
export async function ShowSpinner(text) {
    $("#spinnerImage").show();
    $("#spinnerClose").hide();
    $("#spinnerText").html(text);
    $("#spinnerModal").modal("show");
    $("#spinnerText").removeClass("ispre");
}
export async function HideSpinner(text, success) {
    $("#spinnerImage").hide();
    $("#spinnerClose").show();
    $("#spinnerText").html(text);
    $("#spinnerText").removeClass("ispre");
}
export async function ShowDone() {
    await ShowMessage("Done!");
}
export async function ShowMessage(text, nowrap) {
    $("#spinnerImage").hide();
    $("#spinnerClose").show();
    $("#spinnerText").html(text);
    $("#spinnerModal").modal("show");
    if (nowrap === true) {
        $("#spinnerText").addClass("ispre");
    } else {

        $("#spinnerText").removeClass("ispre");
    }
}
export function ShowSubscribe() {
    $("#subscribeModal").modal("show");
}
export function ShowBuy() {
    $("#buyModal").modal("show");
}
export function ShowModal(modalId) {
    $(modalId).modal("show");
}
export function ShowTransition() {
    $("#runTransition").modal("show");
}
export function ShowLicense() {
    $("#license").modal("show");
}
export function FocusElement(id) {
    const element = document.getElementById(id);
    element.focus();
}
export function GetMetamaskState(net) {
    return getMetamaskState(net);
}
export async function InitializeMetamask(net) {
    return await initializeMetamaskWithTimeout(net);
}
export async function UpdateSparkLines() {
    $('.inlinesparkline').sparkline("html", { width: "100%", height: "60px" });
}
export async function OnPopupClose(popupId, caller, methodName) {
    $("#" + popupId).on("hidden.bs.modal", function () {
        caller.invokeMethodAsync(methodName).then();
    });
}
export async function OpenForPrint(html) {
    var win = window.open("", "Invoice", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" + (screen.height - 400) + ",left=" + (screen.width - 840));
    win.document.body.innerHTML = html;
    win.print();
}
export function GetPageReferrer() {
    return document.referrer;
}
let monaco;
async function loadMonacoEditor() {
    monaco = monaco ?? (await import(/* webpackChunkName: "monaco-editor" */ 'monaco-editor'));
    return monaco;
}
export async function ShowCode(code) {
    $("#ide").css("visibility", "visible");
    let monaco = await loadMonacoEditor();
    if (window.editor === undefined) {
        window.editor = monaco.editor.create(document.getElementById('ide'), {
            language: 'sol',

            lineNumbers: 'on',
            value: code,
            roundedSelection: false,
            scrollBeyondLastLine: false,
            readOnly: false,
            theme: 'vs-dark',
            automaticLayout: true // <<== the important part
        });

    } else {
        window.editor.setValue(code);
    }
}

export function HideCode() {
    //$("#ide").hide();
    monaco.editor.getModels().forEach(model => model.dispose());
    window.editor = undefined;
}
export function Bla() {

}
export function ShowPopOver(element, popover, position) {
    var popoverElement = $("#" + popover);
    if (popoverElement.is(":visible")) {
        popoverElement.hide();
        return;
    }
    if (element == null || element === undefined) {
        return;
    }

    var centerX = 0;
    var centerY = 0;
    if (position === "right") {
        var offset = $(element).offset();
        var width = $(element).outerWidth();

        var height = popoverElement.outerHeight();

        centerX = offset.left + width;
        centerY = offset.top - 45 + $(".leftpane").scrollTop();
    } else if (position === "left") {
        var offset = $(element).offset();
        var width = $(element).outerWidth();

        var height = popoverElement.outerHeight();
        var popoverWidth = 276;

        centerX = offset.left - popoverWidth;
        centerY = offset.top - 45 + $(".leftpane").scrollTop();
    } else if (position === "top") {
        var offset = $(element).offset();
        var width = popoverElement.outerWidth();
        var height = popoverElement.outerHeight();

        centerX = offset.left - width / 2;
        centerY = 0 - height;
    } else {
        var offset = $(element).offset();
        var width = 276;
        var height = $(element).outerWidth();
        var height2 = $(element).outerHeight();

        centerX = offset.left - width / 2 + height / 2;
        centerY = offset.top + height2 + $(".leftpane").scrollTop();
    }

    popoverElement.css("transform", "translate3d(" + centerX + "px, " + centerY + "px, 0px)");
    popoverElement.show();
}

export function ScrollToPosition(element) {
    var elementObj = document.getElementsByName(element)[0];
    if (elementObj) {
        elementObj.scrollIntoView({ behavior: 'smooth' });
        $(elementObj).parent().removeClass("flash");
        setTimeout(function () { $(elementObj).parent().addClass("flash"); }, 600);
    }
}

//MegaMenuRendered
export function LoadMegaMenu() {
    document.dispatchEvent(new CustomEvent("MegaMenuRendered", {}));
}

export function UserClick(element) {
    element.click();
}

export function AcceptMainnet() {
    $(function () {
        $("#dialog-confirm").dialog({
            resizable: false,
            height: "auto",
            width: 400,
            modal: true,
            buttons: {
                "Do it": function () {
                    $(this).dialog("close");
                },
                Cancel: function () {
                    $(this).dialog("close");
                }
            }
        });
    });
}